import React from 'react'
import './Description.css';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import programmer from '../../img/programmer.svg';


const Description = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '100vh',
            minHeight: '720px',
            bgcolor: '#F0ECE5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box
                sx={{
                    width: { xs: '100%', md: '80%' },
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' }, // Cambia la dirección de la distribución
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 4,
                    gap: 1, // Espaciado entre los elementos
                }}
            >
                <img
                    src={programmer}
                    alt="Mi Foto"
                    style={{
                        width: '90%',
                        height: 'auto',
                    }}
                />
                <Typography
                    // variant="h5" 
                    component="div"
                    align="center"
                    gutterBottom 
                    color='#161A30'
                    sx={{
                        typography: { xs: 'h6', md: 'h5' }
                    }}>
                    <h2>¡Hola! </h2>Soy José Eduardo, Programador Web, con experiencia en FrontEnd y BackEnd en el desarrollo de soluciones eficientes. Mi enfoque se centra en la calidad del código, la usabilidad y la escalabilidad.
                    {/* Soy José Eduardo, Programador Web, con experiencia en FrontEnd y BackEnd en el desarrollo de soluciones eficientes, convirtiendo ideas en en sistemas funcionales.
                    Soy José Eduardo, Programador Web dedicado a crear soluciones eficientes y funcionales. Mi enfoque se centra en la calidad del código, la usabilidad y la satisfacción del cliente */}
                </Typography>
            </Box>

        </Box>
    )
}

export default Description