import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import fondo from '../../img/fondo.png';

const Information = ({ colors }) => {
    const { ColorMorado, ColorGris, ColorHueso, ColorMarino } = colors;

    return (
        <Box sx={{
            width: '100%',
            height: 'auto',
            minHeight: '720px',
            bgcolor: ColorMorado, // Color de fondo general
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Box
                sx={{
                    p: 4,
                    width: { xs: '100%', md: '80%' },
                    color: ColorGris, // Color del texto general
                }}>
                <Typography variant="body1"
                    sx={{
                        textAlign: 'justify',
                        typography: { xs: 'body2', md: 'h6' }
                    }}
                >
                    Durante el año 2024, he trabajado como desarrollador full-stack. En el Back-End, he utilizado PHP bajo el modelo MVC, asegurando una arquitectura robusta y mantenible. En el Front-End, he implementado JavaScript, jQuery, y AJAX para la gestión dinámica de vistas y una experiencia de usuario fluida. Además, he incorporado pasarelas de pago como Stripe y Mercado Pago, garantizando transacciones seguras y confiables en los sistemas desarrollados.
                </Typography>

                <Typography variant="body1"
                    sx={{
                        paddingTop: 4,
                        textAlign: 'justify',
                        typography: { xs: 'body2', md: 'h6' }
                    }}
                >
                    Los siguientes trabajos, son proyectos que he realizado con base en los conocimientos y habilidades que he adquirido durante el tiempo que he estado trabajando.
                </Typography>

                {/* DIV DE PROYECTOS */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    {[1, 2, 3, 4].map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: { xs: '95%', md: '40%', lg: '600px' },
                                height: '350px',
                                backgroundImage: `url(${fondo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                position: 'relative',
                                margin: '0 auto',
                                marginTop: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '30%',
                                    backgroundColor: ColorHueso, // Color de fondo de las tarjetas
                                    position: 'absolute',
                                    bottom: 0,
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    p: 1
                                }}
                            >
                                {/* Primera mitad: Título */}
                                <Box
                                    sx={{
                                        width: '35%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: ColorMarino,
                                        typography: { xs: 'button', md: 'h6' }
                                    }}
                                    >
                                        Soy el título
                                    </Typography>
                                </Box>

                                {/* Segunda mitad: Descripción */}
                                <Box
                                    sx={{
                                        width: '65%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    <Typography sx={{
                                        textAlign: 'center',
                                        color: ColorMarino,
                                        typography: { xs: 'caption', md: 'body2' }
                                    }}
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default Information;

// import React from 'react';
// import { Box } from '@mui/system';
// import Typography from '@mui/material/Typography';
// import fondo from '../../img/fondo.png';

// const Information = () => {
//     return (
//         <Box sx={{
//             width: '100%',
//             height: 'auto',
//             minHeight: '720px',
//             bgcolor: '#31304D',
//             display: 'flex',
//             justifyContent: 'center',
//             // alignItems: 'center',
//         }}>
//             <Box
//                 sx={{
//                     p: 4,
//                     width: { xs: '100%', md: '80%' },
//                     color: '#B6BBC4',
//                 }}>
//                 <Typography variant="body1"
//                     sx={{
//                         textAlign: 'justify',
//                         typography: { xs: 'body2', md: 'h6' }
//                     }}
//                 >
//                     Durante el año 2024, he trabajado como desarrollador full-stack. En el Back-End, he utilizado PHP bajo el modelo MVC, asegurando una arquitectura robusta y mantenible. En el Front-End, he implementado JavaScript, jQuery, y AJAX para la gestión dinámica de vistas y una experiencia de usuario fluida. Además, he incorporado pasarelas de pago como Stripe y Mercado Pago, garantizando transacciones seguras y confiables en los sistemas desarrollados.
//                     Estos trabajos son proyectos personales inspirados en mi experiencia previa, desarrollados con base en los conocimientos y habilidades adquiridos durante mi trayectoria.
//                 </Typography>

//                 <Typography variant="body1"
//                     sx={{
//                         paddingTop: 4,
//                         textAlign: 'justify',
//                         typography: { xs: 'body2', md: 'h6' }
//                     }}
//                 >
//                     Los siguientes trabajos, son proyectos que he realizado con base en los conocimientos y habilidades que he adquirido durante el tiempo que he estado trabajando.
//                     {/* Estos trabajos son proyectos personales inspirados en mi experiencia previa, desarrollados con base en los conocimientos y habilidades adquiridos durante mi trayectoria. */}
//                 </Typography>

//                 {/* DIV DE PROYECTOS */}
//                 <Box
//                     sx={{
//                         display: 'flex',
//                         gap: 2,
//                         flexWrap: 'wrap',
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>

//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>

//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>
//                     <Box
//                         sx={{
//                             width: { xs: '95%', md: '40%', lg: '600px' },
//                             height: '350px',
//                             // backgroundColor: 'black',
//                             backgroundImage: `url(${fondo})`, // Reemplaza con la URL de tu imagen
//                             backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el área
//                             backgroundPosition: 'center', // Centra la imagen en el contenedor
//                             backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
//                             position: 'relative', // Necesario para posicionar el div interno
//                             position: 'relative', // Necesario para posicionar el div interno
//                             margin: '0 auto', // Centra el div horizontalmente
//                             marginTop: 4

//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '30%', // 20% del alto del contenedor principal
//                                 backgroundColor: '#F0ECE5',
//                                 position: 'absolute',
//                                 bottom: 0, // Posiciona el div en la parte inferior
                                
//                                 display: 'flex',
//                                 fontWeight: 'bold',
//                                 p: 1
//                             }}
//                         >
//                             {/* Primera mitad: Título */}
//                             <Box
//                                 sx={{
//                                     width: '35%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 <Typography variant="h6" sx={{
//                                     fontWeight: 'bold',
//                                     textAlign: 'center',
//                                     typography: { xs: 'button', md: 'h6' }
//                                 }}
//                                 >
//                                     Soy el título
//                                 </Typography>
//                             </Box>

//                             {/* Segunda mitad: Descripción */}
//                             <Box
//                                 sx={{
//                                     width: '65%', // Ocupa la mitad del ancho
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     overflowY: 'scroll',
//                                 }}
//                             >
//                                 <Typography sx={{
//                                     textAlign: 'center',
//                                     typography: { xs: 'caption', md: 'body2' }
//                                 }}
//                                 >
//                                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisl vel sapien volutpat pharetra.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }

// export default Information